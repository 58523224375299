import { useState } from "react";
// import { Link } from "react-router-dom";
// import Intro from "./MyExpertiseComponent";
import ParallaxMousemove from "react-parallax-mousemove";
import { Link } from "react-scroll";
// import "../styles/animation.css";
// import Navbar from "./Navbar";
import "../styles/Navbar.css"; // Adjust if using CSS modules or styled-components
import logo from "../assets/logo.svg";
import { motion } from "framer-motion";

function SelfIntroPage() {
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    visibleBounce: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300 },
    },
    visibleRotate: {
      opacity: 1,
      y: 0,
      rotate: 360,
      transition: { duration: 2 },
    },
    visibleScale: {
      opacity: 1,
      y: 0,
      scale: [0.8, 1.1, 1],
      transition: { duration: 1.5 },
    },
  };

  const slides = [
    {
      img: "assets/imgs/portfolio/projectimg/ecommers.png",
      subtitle:
        "I am responsible for developing the backend of Gplus, an event management software, using Node.js. My role includes designing and implementing scalable APIs, ensuring seamless data management, and optimizing performance for a smooth user experience. From database integration to server-side logic, I focus on building a robust and efficient backend to support the platform’s event planning and management features.",
      title: "Gplus Events ",
      date: "2025",
      link: "https://www.gplusevents.com/",
    },
    {
      img: "assets/imgs/portfolio/projectimg/fma.png",
      subtitle:
        "I developed the backend for Kaputa Cinema, ensuring a robust and scalable architecture. My responsibilities included designing efficient APIs, database management, and optimizing performance for seamless user interactions.",
      title: "Kaputa Cinema",
      date: "2024",
      link: "http://145.223.21.30/",
    },   
    {
      img: "assets/imgs/portfolio/projectimg/academicweb.png",
      title: "Academic Web App",
      subtitle: "Academic Web leverages the MERN stack (MongoDB, Express.js, React.js, Node.js) for comprehensive schooling management, integrating attendance, HR, student data, transport, exams, and SMS notifications for enhanced operational efficiency and communication. Contributed to backend development using Node.js, MongoDB, and Express.js, ensuring scalable APIs and efficient data handling.",
      date: "2023",
      link: "http://academicweb.com/"
    },    
    {
      img: "assets/imgs/portfolio/projectimg/Tripminder.png",
      title: "Apna Yatri",
      subtitle: "Apna Yatri is an advanced travel management platform developed with NestJS, React, MongoDB, and AWS. It features intuitive booking, real-time tracking, and robust data management, ensuring streamlined travel experiences with comprehensive functionality. Worked on backend development using NestJS, MongoDB, and AWS, optimizing API performance and ensuring secure data handling.",
      date: "2023",
      link: " https://github.com/nmitydv/Apna-Yatri"
    },    
   
    
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrev = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const handleNext = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://personal-portfolio-backend-4y21.onrender.com/api/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
        alert("Your message has been sent successfully!");
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          subject: "",
          message: "",
        });
      } else {
        console.error("Error:", response.statusText);
        // console.error("Error:", await response.text());
        alert("There was a problem with your submission.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was a problem with your submission.");
    }
  };

  return (
    <div className="dark">
      <nav className="navbar">
        <div className="navbar-content">
          <a href="">
            <img src={logo} alt="Logo" className="navbar-logo" />
          </a>
          <ul className="navbar-links">
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#work">Work</a>
            </li>
            {/* <li>
              <a href="#tech-stack">Skills</a>
            </li> */}
            <li>
              <a href="#contact">Contact</a>
            </li>
            <li>
              <a href="https://namit.framer.website/blog" target="blank">Blog&apos;s</a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="smooth-wrapper">
        <div className="smooth-content">
          <main>
            <div className="portfolio__page">
              <div className="swiper portfolio__main-slider">
                <div className="swiper-wrapper portfolio__main-wrapper">
                  <div className="swiper-slide">
                    <section
                      className="portfolio__hero-area portfolio-section"
                      id="home"
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-xxl-12">
                            <div className="portfolio__hero">
                              <h1
                                className="title shape-circle"
                                style={{ fontWeight: "bold" }}
                              >
                                Backend
                              </h1>
                              <h2
                                className="title text-stroke"
                                style={{ fontWeight: "700" }}
                              >
                                Software
                              </h2>
                              <h2
                                className="title"
                                style={{ fontWeight: "bold" }}
                              >
                                Developer
                              </h2>
                              <div className="btn-wrapper">
                                <a
                                  href="https://calendly.com/yadavnamit605/30min"
                                  target="blank"
                                  className="wc-btn-dark"
                                  id="pointer-cursor"
                                >
                                  Hire Me
                                </a>
                              </div>
                              <ParallaxMousemove>
                                <ParallaxMousemove.Layer
                                  layerStyle={{
                                    position: "relative",
                                    zIndex: 1,
                                  }}
                                  config={{
                                    xFactor: 0.1,
                                    yFactor: 0.1,

                                    springSettings: {
                                      stiffness: 50,
                                      damping: 30,
                                    },
                                  }}
                                >
                                  <img
                                    src="assets/imgs/portfolio/myimgg.png"
                                    alt="Personal Portfolio"
                                    className="pp-thumb"
                                  />
                                </ParallaxMousemove.Layer>
                              </ParallaxMousemove>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="swiper-slide">
                    <section
                      className="portfolio-section portfolio__about pt-140 pb-150"
                      id="about"
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-xxl-8 col-xxl-8 col-lg-8">
                            <div className="portfolio__about-left">
                              <motion.h2
                                className="sec-title"
                                initial="hidden"
                                animate="visibleBounce"
                                transition={{ duration: 10 }}
                                variants={variants}
                              >
                                I DELIVER <span>SCALABLE BACKEND SOLUTIONS</span> FOR BUSINESSES
                              </motion.h2>
                              <img src="assets/imgs/shape/16.png" alt="Shape" />
                            </div>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-4">
                            <div className="sec-text">
                              <p>
                              Hey! I’m a backend developer with 2 years of experience working with Node.js and Express.js. With a degree in Computer Science from Vikram University, Ujjain, I blend academic knowledge with hands-on expertise to build efficient and scalable backend solutions. I’m passionate about creating impactful APIs and server-side applications, and I thrive on collaboration and innovation. Ready to turn your backend ideas into reality?                              </p>

                              <Link
                                to="work"
                                smooth={true}
                                duration={500}
                                className="wc-btn-dark"
                                id="pointer-cursor"
                              >
                                Selected Work
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="about-row">
                          <div className="row">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                              <div className="brand-title-wrap">
                                <h3 className="brand-title">
                                  worked with prestigious organization
                                </h3>
                              </div>
                            </div>
                            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8">
                              <div className="brand-list">
                                <div className="brand-logo">
                                  <img
                                    src="assets/imgs/brand/1.png"
                                    alt="Brand Logo"
                                  />
                                </div>
                                <div className="brand-logo">
                                  <img
                                    src="assets/imgs/brand/2.png"
                                    alt="Brand Logo"
                                  />
                                </div>
                                <div className="brand-logo">
                                  <img
                                    src="assets/imgs/brand/3.png"
                                    alt="Brand Logo"
                                    width="70px"
                                  />
                                </div>
                                <div className="brand-logo">
                                  <img
                                    src="assets/imgs/brand/4.png"
                                    alt="Brand Logo"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="swiper-slide">
                    <section
                      className="portfolio-section portfolio__project"
                      id="work"
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-xxl-12">
                            <div className="pp-title-wrap">
                              <h2
                                className="pp-title"
                                style={{ paddingTop: "30px" }}
                              >
                                Selected <br /> Work
                              </h2>
                            </div>
                          </div>
                        </div>

                        <div className="row margins">
                          <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8">
                            <div className="pp-slider-wrapper">
                              <div className="portfolio__project-slider">
                                <div className="pp-slide">
                                  <div className="pp-slide-img">
                                    <a href={slides[currentSlide].link} target="_blank">
                                      <img
                                        src={slides[currentSlide].img}
                                        alt={slides[currentSlide].title}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="pp-next swipper-btn"
                                onClick={handlePrev}
                                style={{ cursor: "pointer" }}
                              >
                                Prev
                              </div>
                              <div
                                className="pp-prev swipper-btn"
                                onClick={handleNext}
                                style={{ cursor: "pointer" }}
                              >
                                Next
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                            <div
                              className="portfolio__project-thumbs"
                              style={{ padding: "20px" }}
                            >
                              <div>
                                <h3
                                  className="pp-slide-title"
                                  style={{
                                    textAlign: "left",
                                    marginBottom: "20px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {slides[currentSlide].title}
                                </h3>

                                <p
                                  className="pp-slide-subtitle"
                                  style={{ marginBottom: "20px" }}
                                >
                                  {slides[currentSlide].subtitle}
                                </p>

                                <p className="pp-slide-date">
                                  {slides[currentSlide].date}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="swiper-slide">
                    <section
                      className="portfolio-section portfolio__service pt-140 pb-140"
                      id="tech-stack"
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6">
                            <h2 className="sec-title">
                              {" "}
                              My <br/> Expertise
                            </h2>
                          </div>
                          <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-6">
                            <div className="sec-text">
                              <p>
                              Looking to elevate backend systems? With experience in Node.js, Express.js, MongoDB, and cloud technologies, I&apos;m eager to contribute and help build scalable solutions.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="portfolio__service-list">
                          <div className="row">
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <div className="portfolio__service-item">
                                <a href="#tech-Stack">
                                  <h3 className="ps-title">
                                    Languages
                                  </h3>
                                  <ul>
                                    <li>+ Javascript</li>
                                    <li>+ Java</li>
                                  </ul>
                                </a>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <div className="portfolio__service-item">
                                <a href="#tech-Stack">
                                  <h3 className="ps-title">
                                    Frameworks
                                  </h3>
                                  <ul>
                                    <li>+ Node</li>
                                    <li>+ Express</li>
                                    <li>+ Nest</li>
                                  </ul>
                                </a>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                              <div className="portfolio__service-item">
                                <a href="#tech-Stack">
                                  <h3 className="ps-title">
                                    Database & 
                                    <br /> Cloud
                                  </h3>
                                  <ul>
                                    <li>+ Mongodb</li>
                                    <li>+ Version Control</li>
                                    <li>+ AWS</li>
                                    <li>+ Docker & Kubernetes</li>
                                  </ul>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>service
                        <div className="row">
                          <div className="col-xxl-12">
                            <div className="ps-btn">
                              <a href="https://wa.me/919754392029">
                                Contact me to get more extra details !!{" "}
                                <strong style={{ cursor: "pointer" }}>
                                  CONTACT NOW
                                </strong>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  {/* <Intro /> */}
                  <div className="swiper-slide">
                    <section
                      className="portfolio-section portfolio__footer-area  pt-130"
                      id="contact"
                    >
                      {/* Contact area start */}
                      <div className="">
                        <div className="container">
                          <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <div className="sec-title-wrapper">
                              <h2 className="sec-title">
                              <p className="pf-title">Let’s get in touch 🤟</p>
                            </h2>
                              </div>
                            </div>
                            {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                              <div className="contact__text">
                                <p>
                                  Excited to begin our journey together! For any
                                  inquiries, feel free to reach out to us.
                                </p>
                              </div>
                            </div> */}
                          </div>
                          <div className="row">
                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5">
                              <div className="pf-contact">
                                <h3>
                                  Hello! Let’s innovate <br />
                                  for the next generation!
                                </h3>
                                <ul>
                                  <li>
                                    <a
                                      href="tel:+919754392029"
                                      className="pointer-cursor"
                                    >
                                      +(91) 9754392029
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="mailto:yadavnamit605@gmail.com"
                                      className="pointer-cursor"
                                    >
                                      yadavnamit605@gmail.com
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="pf-social">
                                <h3>Let’s Connect </h3>
                                <ul>
                                  <li>
                                    <a
                                      href="https://github.com/nmitydv/"
                                      className="pointer-cursor"
                                    >
                                      Github
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.linkedin.com/in/nmitydv/"
                                      className="pointer-cursor"
                                    >
                                      Linkdin
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://leetcode.com/u/Nmitydv2412/"
                                      className="pointer-cursor"
                                    >
                                      Leetcode
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                              <div className="contact__form">
                                <form id="contactForm" onSubmit={handleSubmit}>
                                  <div className="row g-3">
                                    <div className="col-xxl-6 col-xl-6 col-12">
                                      <input
                                        id="pointer-text"
                                        type="text"
                                        name="name"
                                        placeholder="Name *"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-12">
                                      <input
                                        id="pointer-text"
                                        type="email"
                                        name="email"
                                        placeholder="Email *"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="row g-3">
                                    <div className="col-xxl-6 col-xl-6 col-12">
                                      <input
                                        id="pointer-text"
                                        type="tel"
                                        name="phoneNumber"
                                        placeholder="Phone"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-12">
                                      <input
                                        id="pointer-text"
                                        type="text"
                                        name="subject"
                                        placeholder="Subject *"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="row g-3">
                                    <div className="col-12">
                                      <textarea
                                        id="pointer-text"
                                        name="message"
                                        placeholder="Messages *"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="row g-3">
                                    <div className="col-12">
                                      <div className="pc-btn">
                                        <button
                                          type="submit"
                                          className="wc-btn-dark"
                                          id="pointer-cursor"
                                        >
                                          Get in Touch
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Contact area end */}
                      {/* Footer area start */}
                      <footer className="portfolio__footer">
                        <div className="container">
                          <div className="pf-btm">
                            <div className="row">
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-5">
                                <div className="footer__copyright-2">
                                  <p>
                                    © 2023 - 2025 | Alrights reserved by{" "}
                                    <a
                                      href=""
                                      target="_blank"
                                      id="pointer-cursor"
                                    >
                                      devnamit
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-7">
                                <div className="footer__nav">
                                  <ul className="footer-menu menu-anim">
                                    <li>
                                      <Link
                                        to="home"
                                        smooth={true}
                                        duration={400}
                                        id="pointer-cursor"
                                      >
                                        Home
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="about"
                                        smooth={true}
                                        duration={400}
                                        id="pointer-cursor"
                                      >
                                        About
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="work"
                                        smooth={true}
                                        duration={400}
                                        id="pointer-cursor"
                                      >
                                        Work
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="tech-stack"
                                        smooth={true}
                                        duration={400}
                                        id="pointer-cursor"
                                      >
                                        Skills
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </footer>
                      {/* Footer area end */}
                    </section>
                  </div>
                </div>
                <div className="swiper-pagination circle-pagination-2" />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default SelfIntroPage;
